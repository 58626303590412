<template>
  <div>
    <b-card>
      <b-card-header class="pb-50">
        <h4>
          {{ this.$i18n.t('settings.platform-groups.add_new_group') }}
        </h4>
      </b-card-header>
      <b-card-body>
        <validation-observer
            ref="refFormObserver"
        >
          <!-- Form -->
          <b-form
              @submit.prevent="()=>{}"
          >
            <b-row>
              <b-col cols="10">
                <!-- Name -->
                <validation-provider
                    #default="{errors}"
                    name="name"
                    rules=""
                >
                  <b-form-group
                      :label="$i18n.t('Name')"
                      label-for="name"
                  >
                    <b-form-input
                        id="name"
                        v-model="group.name"
                        :state="errors.length > 0 ? false:null"
                        autofocus
                        placeholder="Name"
                        trim
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col cols="2">
                <!-- Form Actions -->
                <div class="d-flex mt-2">
                  <b-button
                      :disabled="isLoadingAdd"
                      class="mr-2 w-100"
                      type="button"
                      variant="primary"
                      @click="addItem"
                  >
                    <span>{{ $i18n.t('save') }}</span>
                  </b-button>
                </div>
              </b-col>
            </b-row>
          </b-form>
        </validation-observer>
      </b-card-body>
    </b-card>
    <loading :show="isLoading">
      <b-card>
        <b-table
            ref="table"
            :current-page="currentPage"
            :empty-text="$i18n.t('table.no_data')"
            :fields="fields"
            :items="items"
            :per-page="perPage"
            hover
            responsive
            show-empty
        >
          <template #cell(name)="data">
            <div v-if="data.item.id === itemEdited.id">
              <b-input-group>
                <b-form-input
                    v-model="itemEdited.name"
                    trim
                    @keyup.enter="updateItem"
                />
                <b-input-group-append>
                  <b-button
variant="outline-primary"
@click="updateItem"
>
                    <feather-icon icon="CheckIcon" />
                  </b-button>
                </b-input-group-append>
              </b-input-group>
            </div>
            <div v-else>
              {{ data.item.name }}
            </div>
          </template>

          <template #cell(actions)="data">
            <feather-icon
icon="EditIcon"
@click="itemEdited = JSON.parse(JSON.stringify(data.item))"
/>
            <feather-icon
                class="ml-2"
                icon="TrashIcon"
                @click="confirmDelete(data.item.id)"
            />
          </template>
        </b-table>
        <b-pagination
            v-model="currentPage"
            :per-page="perPage"
            :total-rows="items.length"
            class="my-0 ml-auto"
        />
      </b-card>
    </loading>
  </div>
</template>
<script>
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import {required} from '@validations'

export default {
  data() {
    return {
      items: [],
      fixItems: [],
      isLoading: false,
      perPage: 25,
      currentPage: 1,
      group: {
        name: '',
      },
      isLoadingAdd: false,
      required,
      itemEdited: {
        id: '',
        name: '',
      },
    }
  },
  computed: {
    fields() {
      return [
        {
          key: "name",
          label: this.$i18n.t('Name'),
          sortable: true
        },
        {
          key: 'actions',
          label: this.$i18n.t('table.Actions'),
          sortable: false,
        }
      ]
    }
  },
  mounted() {
    this.getData()
  },
  methods: {
    updateItem(){
      this.isLoading = true
      this.$http.put(`/settings/platform-groups/${this.itemEdited.id}`, {name: this.itemEdited.name}).then(response => {
        this.itemEdited = {
          id: '',
          name: '',
        }
        this.getData()
        this.$toast({
          component: ToastificationContent,
          props: {
            title: this.$i18n.t('Success'),
            icon: 'EditIcon',
            variant: 'success',
            text: response.data.message
          },
        })
      }).catch(error => {
        if (error.data && error.data.errors) {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Error',
              icon: 'TrashIcon',
              variant: 'danger',
              text: error.data.errors.name[0],
            },
          })
        }
      })
      this.isLoading = false
    },
    deleteItem(id){
      this.isLoading = true
      this.$http.delete(`/settings/platform-groups/${id}`).then(response => {
        this.getData()
        this.$toast({
          component: ToastificationContent,
          props: {
            title: this.$i18n.t('Deleted'),
            icon: 'EditIcon',
            variant: 'success',
            text: response.data.message
          },
        })
      })
      this.isLoading = false
    },
    confirmDelete(id) {
      this.$swal({
        title: this.$i18n.t('Are you sure?'),
        text: this.$i18n.t("messages.not_able_revert"),
        icon: this.$i18n.t('warning'),
        showCancelButton: true,
        confirmButtonText: this.$i18n.t('Yes, delete it'),
        cancelButtonText: this.$i18n.t('Cancel'),
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
         this.deleteItem(id)
        }
      })
    },
    async getData() {
      this.isLoading = true
      const res = await this.$http.get('settings/platform-groups')
      this.items = res.data.data
      this.isLoading = false
    },
    addItem() {
      if (!this.isLoadingAdd) {
        this.isLoadingAdd = true
        this.$http.post('/settings/platform-groups', {name: this.group.name}).then(response => {
          this.isLoadingAdd = false
          this.group.name = ""
          this.$refs.refFormObserver.reset()
          this.getData()
          this.$toast({
            component: ToastificationContent,
            props: {
              title: this.$i18n.t('Success'),
              icon: 'EditIcon',
              variant: 'success',
              text: response.data.message
            },
          })
        }).catch(error => {
          this.isLoadingAdd = false
          if (error.data && error.data.errors) {
            this.$refs.refFormObserver.setErrors(error.data.errors)
          }
        })
      }
    },
  }
}
</script>
